import React from 'react';
import { FaDiscord } from 'react-icons/fa';
import { HiOutlineViewGrid } from 'react-icons/hi';
import { MdEditCalendar, MdOutlineTextSnippet } from 'react-icons/md';
import { BsMedium, BsTelegram } from 'react-icons/bs';

export interface Icons {
  discord: React.ReactElement;
  communities: React.ReactElement;
  whitepaper: React.ReactElement;
  ambassador: React.ReactElement;
  medium: React.ReactElement;
  roadmap: React.ReactElement;
}

interface Props {
  iconName: keyof Icons;
  name: string;
  description: string;
  href: string;
}

const FlyoutItem = ({ iconName, name, description, href }: Props) => {
  const icons: Icons = {
    discord: <FaDiscord className="w-full max-w-[2rem]" />,
    communities: <BsTelegram className="w-full max-w-[2rem]" />,
    whitepaper: <MdOutlineTextSnippet className="w-full max-w-[2rem]" />,
    ambassador: <HiOutlineViewGrid className="w-full max-w-[2rem]" />,
    medium: <BsMedium className="w-full max-w-[2rem]" />,
    roadmap: <MdEditCalendar className="w-full max-w-[2rem]" />,
  };

  const icon = icons[iconName];

  return (
    <a
      key={name}
      href={href}
      target="blank"
      className="flex items-center lg:items-start -m-3 p-2 lg:p-3 text-4xl rounded-md text-main-green bg-dark-grey hover:bg-light-grey transition ease-in-out gap-5 duration-150"
    >
      {icon}
      <div>
        <p className="text-sm lg:text-base font-medium text-white">{name}</p>
        <p className="mt-1 text-sm font-normal text-dark-white hidden lg:block">{description}</p>
      </div>
    </a>
  );
};

export default FlyoutItem;
