import React, { Fragment } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { useTranslation } from 'react-i18next';
import FlyoutItem, { Icons } from './FlyoutItem';

interface Resources {
  id: number;
  iconName: keyof Icons;
  name: string;
  description: string;
  href: string;
}

const resources: Resources[] = [
  {
    id: 1,
    iconName: 'discord',
    name: 'RESOURCE_DISCORD_NAME',
    description: 'RESOURCE_DISCORD_DESCRIPTION',
    href: 'https://discord.gg/w6KREwnpBj',
  },
  {
    id: 2,
    iconName: 'communities',
    name: 'RESOURCE_COMMUNITY_NAME',
    description: 'RESOURCE_COMMUNITY_DESCRIPTION',
    href: 'https://t.me/StackOSAnnouncements',
  },
  // {
  //   id: 3,
  //   iconName: 'whitepaper',
  //   name: 'RESOURCE_WHITEPAPER_NAME',
  //   description: 'RESOURCE_WHITEPAPER_DESCRIPTION',
  //   href: 'https://docsend.com/view/wq7qxzjk7zsd3wph',
  // },
  {
    id: 3,
    iconName: 'ambassador',
    name: 'RESOURCE_AMBASSADOR_NAME',
    description: 'RESOURCE_AMBASSADOR_DESCRIPTION',
    href: 'https://tally.so/r/3xN4Ro',
  },
  {
    id: 4,
    iconName: 'medium',
    name: 'RESOURCE_MEDIUM_NAME',
    description: 'RESOURCE_MEDIUM_DESCRIPTION',
    href: 'https://medium.com/stackos',
  },
  // {
  //   id: 6,
  //   iconName: 'roadmap',
  //   name: 'RESOURCE_ROADMAP_NAME',
  //   description: 'RESOURCE_ROADMAP_DESCRIPTION',
  //   href: 'https://stackosoverview.notion.site/stackosoverview/StackOS-Stages-0590f5a0fd8149b98d3d73d0f57a7dec',
  // },
];

const FlyoutNav = () => {
  const { t } = useTranslation();

  return (
    <Popover className="relative z-50">
      {() => (
        <>
          <Popover.Button className="rounded-md inline-flex items-center focus:outline-none hover:text-main-green duration-500">
            <span>Resources</span>
            <ChevronDownIcon
              className="ml-2 h-5 w-5 group-hover:text-gray-500"
              aria-hidden="true"
            />
          </Popover.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute z-10 left-1/2 transform -translate-y-[78%] lg:-translate-y-0 -translate-x-1/2 mt-3 px-2 w-screen max-w-md sm:px-0">
              {({ close }) => (
                <div
                  onMouseLeave={() => close()}
                  className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden"
                >
                  <div className="relative grid gap-6 p-5 bg-dark-grey sm:gap-8 sm:p-8">
                    {resources.map((item) => (
                      <FlyoutItem
                        key={item.id}
                        iconName={item.iconName}
                        name={t(item.name)}
                        description={t(item.description)}
                        href={item.href}
                      />
                    ))}
                  </div>
                </div>
              )}
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default FlyoutNav;
